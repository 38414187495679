import {
  Box,
  Button,
  Grid,
  Typography,
  Chip,
  Card,
  CardContent,
  Stack,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom/";
import { MEASURE_TYPES, DEVICE_STATE_STATUS } from "../../utils/constants";
import { get, isAdmin } from "../../utils/io";
import { useModal, usePagination } from "../hooks";
import MainContentContainer from "../Layouts/MainContentContainer";
import StelDataGrid from "../StelDataGrid";
import StelModal from "../StelModal";
import BulkRegisterNewDevicesForm from "./BulkRegisterNewDevicesForm";
import DeviceSearchForm from "./DeviceSearchForm";
import DevicesToolbar from "./DevicesToolbar";
import ImportDevice from "./ImportDevice";
import MoveRegistrationsForm from "./MoveRegistrationsForm";

const emptySearchInputs = {
  macAddress: "",
  serialNumber: "",
  status: "",
  type: "",
  hubId: "",
  isBlocked: false,
  includeImage: true,
};

const initialTotalCountStatus = {
  total_count: 0,
  counts_by_status: {
    inventory: 0,
    registered: 0,
    running: 0,
    expired: 0,
  },
};

function Devices() {
  const [loading, setLoading] = useState(false);
  const { isModal, openModal, closeModal } = useModal();
  const {
    isModal: isMoveRegistrationsModal,
    openModal: openMoveRegistrationsModal,
    closeModal: closeMoveRegistrationsModal,
  } = useModal();
  const { pagination, handlePageChange, handleRowPerPageChange } =
    usePagination();
  const [devices, setDevices] = useState([]);
  const [searchInputs, setSearchInputs] = useState({ ...emptySearchInputs });
  const [rowCount, setRowCount] = useState(0);
  const [totalCountStatus, setTotalCountStatus] = useState(
    initialTotalCountStatus
  );
  const [g7ModelId, setG7ModelId] = useState(0);
  const [refreshStats, setRefreshStats] = useState({});
  const [showExpiredDevices, setShowExpiredDevices] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [useFromHub, setUseFromHub] = useState(true);
  const [openImportDevices, setOpenImportDevices] = useState(false);

  const history = useHistory();

  const deviceStatusInfo = useMemo(
    () => [
      {
        label: "Total Count",
        value: totalCountStatus.total_count,
      },
      {
        label: "In Inventory",
        value: totalCountStatus.counts_by_status.inventory,
      },
      {
        label: "Registered",
        value: totalCountStatus.counts_by_status.registered,
      },
      {
        label: "Running",
        value: totalCountStatus.counts_by_status.running,
      },
      {
        label: "Completed",
        value: totalCountStatus.counts_by_status.expired,
      },
    ],
    [totalCountStatus]
  );

  const handleRowsSelection = (ids) => {
    const selectedRowsData = ids.map((id) =>
      devices.find((row) => row.id === id)
    );
    setSelectedRows(selectedRowsData);
  };

  const handleMoveRegistrations = () => {
    setUseFromHub(false);
    openMoveRegistrationsModal();
  };

  useEffect(() => {
    const getDevices = async () => {
      setLoading(true);
      const payload = {
        mac_address: searchInputs?.macAddress,
        is_blocked: searchInputs?.isBlocked,
        serial_number: searchInputs?.serialNumber,
        make_id: searchInputs?.makeId,
        state: searchInputs?.status
          ? JSON.stringify({ status: searchInputs?.status.toUpperCase() })
          : null,
        state_ne:
          !showExpiredDevices &&
          searchInputs?.status !== DEVICE_STATE_STATUS.EXPIRED.value
            ? JSON.stringify({ status: DEVICE_STATE_STATUS.EXPIRED.value })
            : null,
        model_id: g7ModelId,
        registered_esn: searchInputs?.hubId,
        offset: pagination.pageSize * pagination.page,
        limit: pagination.pageSize,
        include_image_url: false,
      };

      const res = await get("/devices", payload);
      if (res?.status === 200) {
        setDevices(res.data.devices);
        setRowCount(res.data.total_count);
      } else {
        enqueueSnackbar("Unable to retrieve devices", {
          variant: "error",
        });
      }
      setLoading(false);
    };
    // Don't find devices until `g7ModelId` is set the correct value
    if (g7ModelId !== 0) {
      getDevices();
    }
  }, [showExpiredDevices, searchInputs, pagination, g7ModelId]);

  useEffect(() => {
    const getDexcomStats = async () => {
      const res = await get("/dexcom/stats");
      if (res?.status === 200) {
        setTotalCountStatus(res.data);
      }
    };
    getDexcomStats();
  }, [refreshStats]);

  useEffect(() => {
    const setDexcomModelId = async () => {
      const res = await get("/device_models");
      setG7ModelId(
        res.data.device_models.find((model) => model.name === "G7").id
      );
    };
    setDexcomModelId();
  }, []);

  const DataGridColumns = [
    {
      field: "serial_number",
      flex: 0.8,
      headerName: "Serial Number",
      valueGetter: (params) => params.row.serial_number,
    },
    {
      field: "status",
      flex: 0.7,
      headerName: "Status",
      renderCell: (params) => (
        <div>
          {params.row.status && (
            <DeviceStatusIndicator status={params.row.status} />
          )}
        </div>
      ),
    },
    {
      field: "last_used",
      flex: 1,
      headerName: "Last Used",
      renderCell: (params) =>
        params.row.last_used_time
          ? new Date(params.row.last_used_time).toLocaleString()
          : "",
    },
    {
      field: "registered_hub",
      flex: 0.8,
      headerName: "Registered Hub",
    },
    {
      field: "mac_address",
      flex: 0.8,
      headerName: "MAC Address",
    },
    {
      field: "type",
      flex: 0.8,
      headerName: "Type",
      valueGetter: (params) => MEASURE_TYPES[params.row.type]?.label,
    },
    {
      field: "make_model",
      flex: 0.7,
      headerName: "Make/Model",
      renderCell: (params) =>
        `${params.row.make || ""} ${params.row.model_name || ""}`,
    },
  ];

  const refreshDevicesGrid = () => setSearchInputs({ ...searchInputs });

  return (
    <MainContentContainer
      title={
        <Grid container alignItems="center" justifyContent="space-between">
          <Typography
            component="h1"
            sx={{ fontSize: 24, fontWeight: 700, margin: 0 }}
          >
            Dexcom Device Management
          </Typography>
          {isAdmin() && (
            <Button
              sx={{ display: "flex" }}
              color="primary"
              type="submit"
              variant="contained"
              onClick={() => setOpenImportDevices(true)}
            >
              Import Devices
            </Button>
          )}
        </Grid>
      }
      analyticsTitle="Live Devices"
    >
      <Grid item sx={{ mb: 2 }}>
        <Card>
          <CardContent
            sx={{
              m: 2,
              mr: 0,
              display: "flex",
              alignItems: "center",
            }}
          >
            {[...deviceStatusInfo].map((info) => {
              if (!info) return null;
              return (
                <Box sx={{ mr: 8 }} key={info.label}>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: 16,
                      mb: 1,
                      color: "#1e1e1e",
                    }}
                  >
                    {info.label}
                  </Typography>
                  {typeof info.value !== "string" ? (
                    info.value
                  ) : (
                    <Typography sx={{ fontSize: 14, color: "#545454" }}>
                      {info.value}
                    </Typography>
                  )}
                </Box>
              );
            })}
          </CardContent>
        </Card>
      </Grid>
      <DeviceSearchForm onSubmit={setSearchInputs} isSearchDisabled={loading} />
      <Box sx={{ mt: 2 }}>
        {!isAdmin() && (
          <Stack
            sx={{
              flexDirection: "row",
              justifyContent: "flex-end",
              mb: 2,
              gap: 2,
            }}
          >
            <Button
              sx={{ display: "flex" }}
              color="primary"
              type="submit"
              variant="contained"
              onClick={openModal}
            >
              Register Devices
            </Button>
            <Button
              sx={{ display: "flex" }}
              color="primary"
              type="submit"
              variant="contained"
              onClick={() => {
                setUseFromHub(true);
                openMoveRegistrationsModal();
              }}
            >
              Move Registrations
            </Button>
          </Stack>
        )}
        <StelDataGrid
          loading={loading}
          columns={DataGridColumns}
          rows={devices}
          rowsPerPageOptions={[20, 25, 50, 100]}
          pageSize={pagination.pageSize}
          onPageChange={handlePageChange}
          onPageSizeChange={handleRowPerPageChange}
          rowCount={rowCount}
          paginationMode="server"
          checkboxSelection
          onRowClick={(row) => history.push(`/devices/${row.id}`)}
          components={{ Toolbar: !isAdmin() && DevicesToolbar }}
          componentsProps={{
            toolbar: {
              showExpiredDevices,
              setShowExpiredDevices,
              showExpiredDevicesDisabled:
                (searchInputs?.status || "").length > 0,
              isDisabled: selectedRows.length === 0,
              onMoveRegistrations: handleMoveRegistrations,
            },
          }}
          onSelectionModelChange={(ids) => handleRowsSelection(ids)}
          isRowSelectable={(params) =>
            [
              DEVICE_STATE_STATUS.REGISTERED.value,
              DEVICE_STATE_STATUS.RUNNING.value,
            ].includes(params.row.status)
          }
        />
      </Box>
      <ImportDevice
        open={openImportDevices}
        onClose={() => setOpenImportDevices(false)}
      />
      {isModal && (
        <StelModal
          open={!!isModal}
          onClose={closeModal}
          title="Register Devices"
          width={650}
        >
          <BulkRegisterNewDevicesForm
            onClose={() => {
              closeModal();
              refreshDevicesGrid();
            }}
            g7ModelId={g7ModelId}
            onSuccess={() => setRefreshStats({})} // Refresh stats after registration
          />
        </StelModal>
      )}

      {isMoveRegistrationsModal && (
        <StelModal
          open={!!isMoveRegistrationsModal}
          onClose={closeMoveRegistrationsModal}
          title="Move Registrations"
          width={650}
        >
          <Typography sx={{ mb: "10px" }}>
            {useFromHub
              ? "Move registrations from one hub to another. Only active (non-completed) devices can be moved."
              : "Move registrations for the selected devices to another hub. Note that only active (non-completed) devices can be moved."}
          </Typography>
          <MoveRegistrationsForm
            onClose={closeMoveRegistrationsModal}
            onSuccess={refreshDevicesGrid}
            useFromHub={useFromHub}
            selectedDevices={selectedRows}
          />
        </StelModal>
      )}
    </MainContentContainer>
  );
}

export function DeviceStatusIndicator(props) {
  const { status, ...rest } = props;
  return (
    <Chip
      label={DEVICE_STATE_STATUS[status.toUpperCase()].label}
      size="small"
      color={DEVICE_STATE_STATUS[status.toUpperCase()].color}
      {...rest}
    />
  );
}

DeviceStatusIndicator.propTypes = {
  status: PropTypes.string.isRequired,
};

export default Devices;
